import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/content/blog/implementing-dsl-in-ruby-csv-importer/index.mdx";
import React from "react";
import {graphql} from "gatsby";
import Seo from "components/SEO";
import Container from "components/Container";
import Layout from "../components/Layout";
import config from "../../config/website";
import PostTitle from "../components/PostTitle";
import Comments from "../components/Comments";
function Post({data: {site, mdx}, children}) {
  const author = mdx.frontmatter.author || config.author;
  const date = mdx.frontmatter.date;
  const title = mdx.frontmatter.title;
  const banner = mdx.frontmatter.banner;
  const tags = mdx.frontmatter.tags;
  return React.createElement(Layout, {
    site: site,
    frontmatter: mdx.frontmatter
  }, React.createElement(Seo, {
    frontmatter: mdx.frontmatter,
    isBlogPost: true
  }), React.createElement("article", null, React.createElement(Container, null, !mdx.frontmatter.static && React.createElement(PostTitle, {
    title: title,
    date: date,
    author: author,
    banner: banner,
    tags: tags
  }), children, !mdx.frontmatter.static && React.createElement(Comments))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Post, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String!) {
    site {
      ...site
    }
    mdx(fields: { id: { eq: $id } }) {
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        author
        tags
        #        banner {
        #          childImageSharp {
        #            fluid(maxWidth: 900) {
        #              ...GatsbyImageSharpFluid_withWebp_tracedSVG
        #            }
        #          }
        #        }
        slug
        keywords
        static
      }
    }
  }
`;
