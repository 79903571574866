/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    h2: "h2",
    pre: "pre",
    em: "em",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ruby is famous, among many things, for its beautiful domain-specific languages (DSLs). The popularity is well-deserved: everyone loves to use DSLs for their efficient, often declarative, description of intention with very little syntactic noise of typical imperative implementations. However, surprisingly few Ruby programmers can implement a DSL on their own. Let's change it, one blog post at a time."), "\n", React.createElement(_components.p, null, "In this project, we'll implement a simple CSV importer with type coercions. We're going to use only simple techniques such as blocks and ", React.createElement(_components.code, null, "proc"), " objects, no actual metaprogramming (no ", React.createElement(_components.code, null, "define_method"), ", ", React.createElement(_components.code, null, "instance_eval"), "), etc."), "\n", React.createElement(_components.h2, null, "The syntax"), "\n", React.createElement(_components.p, null, "Here's what we'd like to achieve:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "CSVImport.from_file('people.csv') do |config|\n  config.string :first_name, column: 1\n  config.string :last_name, column: 2\n  config.integer :age, column: 4\n  config.decimal :salary, column: 5\nend\n")), "\n", React.createElement(_components.p, null, "I like to practice outside-in approach to implement DSLs. Worrying about the details will only distract us from creating the proper structure for our project. The best way to start is to create empty no-op classes and methods so that Ruby will be able to run our program without crashing."), "\n", React.createElement(_components.h2, null, "The no-op skeleton"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "class CSVImport\n  def self.from_file(filepath)\n  end\nend\n\nCSVImport.from_file('people.csv') do |config|\n  config.string :first_name, column: 1\n  config.string :last_name, column: 2\n  config.integer :age, column: 4\n  config.decimal :salary, column: 5\n  puts 'please, call me!'\nend\n")), "\n", React.createElement(_components.p, null, "Running this snippet will result in our program exiting without doing anything. However, the ", React.createElement(_components.code, null, "puts 'please, call me!'"), " line never gets called – we never call the entire block containing this statement. Here we have a significant rule of constructing no-op skeletons: we need to make sure that every block and every method is called."), "\n", React.createElement(_components.p, null, "Let's observe the way we pass the configuration in the DSL. ", React.createElement(_components.code, null, "CSVImport"), " class has ", React.createElement(_components.code, null, "from_file"), " class method that takes a block with one argument (", React.createElement(_components.code, null, "config"), "). This object has several methods (", React.createElement(_components.code, null, "string"), ", ", React.createElement(_components.code, null, "integer"), ", ", React.createElement(_components.code, null, "decimal"), ") and is used to describe the schema of our imported CSV files. First, let's define the schema class:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "class CSVImportSchema\n  def string(name, column:)\n    puts 'string'\n  end\n\n  def integer(name, column:)\n    puts 'integer'\n  end\n\n  def decimal(name, column:)\n    puts 'decimal'\n  end\nend\n")), "\n", React.createElement(_components.p, null, "Then, make sure to pass the configuration block:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "class CSVImport\n  def self.from_file(filepath)\n    schema = CSVImportSchema.new\n    yield schema\n  end\nend\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "from_file"), " method receives an implicit block as an argument. This block expects to be called with a schema object as an argument. We can do it by calling ", React.createElement(_components.code, null, "yield"), " with the object we'd like to pass to the block."), "\n", React.createElement(_components.p, null, "Here's how the method would look like in a more explicit form (which is rarely used):"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "def self.from_file(filepath, &block)\n  schema = CSVImportSchema.new\n  block.call(schema)\nend\n")), "\n", React.createElement(_components.p, null, "Running the program will result in the following output:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "string\nstring\ninteger\ndecimal\nplease, call me!\n")), "\n", React.createElement(_components.p, null, "Now we have a true no-op skeleton. We designed our DSL, and we made sure that every piece of it is appropriately called. Our next step is implementing the process of importing the CSV file."), "\n", React.createElement(_components.h2, null, "Storing the schema"), "\n", React.createElement(_components.p, null, "We decided in the beginning that the importer will do type coercion on each column, so we need to find a way to store each type along with the method to coerce it. Approaching it with strict object-oriented approach would most likely result in a set of classes with a polymorphic interface. But since the only thing we need for a type to store is coercion code, we can radically simplify it and use ", React.createElement(_components.em, null, "functions"), " as types. Or lambdas, to be more Ruby-specific."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "str = ->(x) { x.to_s }\nint = ->(x) { x.to_i }\n\nstr.call(123)   # => \"123\"\nint.call('123') # => 123\n\nstr             # #<Proc:0x00007fd5ac989c00@(irb):1 (lambda)>\n")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "CSVImportSchema"), " could be, therefore, implemented like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "class CSVImportSchema\n  attr_reader :columns\n  Column = Struct.new(:name, :col_number, :type)\n\n  def initialize\n    @columns = []\n  end\n\n  def string(name, column:)\n    @columns << Column.new(name, column, ->(x) { x.to_s })\n  end\n\n  def integer(name, column:)\n    @columns << Column.new(name, column, ->(x) { x.to_i })\n  end\n\n  def decimal(name, column:)\n    @columns << Column.new(name, column, ->(x) { x.to_f })\n  end\nend\n")), "\n", React.createElement(_components.h2, null, "Data processing"), "\n", React.createElement(_components.p, null, "Now that we have our schema with type coercion defined, we can import the CSV file and process each row to produce objects defined by the schema:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "class CSVImport\n  attr_reader :schema\n\n  def initialize\n    @schema = CSVImportSchema.new\n  end\n\n  def self.from_file(filepath)\n    import = new\n    yield import.schema\n    rows = CSV.read(filepath, col_sep: ';')\n    import.process(rows)\n  end\n\n  def process(rows)\n    rows.map { |row| process_row(row) }\n  end\n\n  private\n\n  def process_row(row)\n    obj = {}\n    @schema.columns.each do |col|\n      obj[col.name] = col.type.call(row[col.col_number - 1])\n    end\n    obj\n  end\nend\n")), "\n", React.createElement(_components.p, null, "Now when we run the importer against an actual file:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "CSVImport.from_file('people.csv') do |config|\n  config.string :first_name, column: 1\n  config.string :last_name, column: 2\n  config.integer :age, column: 4\n  config.decimal :salary, column: 5\nend\n")), "\n", React.createElement(_components.p, null, "We'll get the following output:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "...\n{:first_name=>\"Oleta\", :last_name=>\"Raynor\", :age=>38, :salary=>118600.0}\n{:first_name=>\"Tyra\", :last_name=>\"Johns\", :age=>34, :salary=>65700.0}\n{:first_name=>\"Reinhold\", :last_name=>\"Koch\", :age=>27, :salary=>51900.0}\n{:first_name=>\"Mary\", :last_name=>\"Stanton\", :age=>58, :salary=>46800.0}\n{:first_name=>\"Lyric\", :last_name=>\"Kub\", :age=>52, :salary=>105300.0}\n{:first_name=>\"Violette\", :last_name=>\"Lakin\", :age=>69, :salary=>81300.0}\n...\n")), "\n", React.createElement(_components.p, null, "Our work is almost done."), "\n", React.createElement(_components.h2, null, "Refactoring"), "\n", React.createElement(_components.p, null, "The code works as intended, but its structure could be improved:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Wrap all classes in a namespace"), "\n", React.createElement(_components.li, null, "Split the importer from the orchestration"), "\n", React.createElement(_components.li, null, "Importer should only take an already-formed schema object, leaving the forming of the schema to the orchestration"), "\n", React.createElement(_components.li, null, "Expose the orchestration from the top-level module"), "\n"), "\n", React.createElement(_components.p, null, "You can see the result of the refactoring ", React.createElement(_components.a, {
    href: "https://github.com/razorjack/dsl_csv_import/blob/master/dsl.rb"
  }, "on GitHub"), "."), "\n", React.createElement(_components.h2, null, "Wrapping up"), "\n", React.createElement(_components.p, null, "The implementation of our DSL resulted in about 50 lines of code – it can fit one screen. DSLs don't have to involve heavy metaprogramming. DSLs are a part of what makes Ruby so beautiful and are an essential component of designing clean and usable APIs for other developers."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
